<template>
  <div class="home-banners" :class="[type]">
    <Carousel
      v-if="!loading && home_banners.length"
      :per-page="1"
      :pagination-enabled="false"
      :navigation-enabled="true"
      :loop="true"
      :navigation-next-label="''"
      :navigation-prev-label="''"
      :pagination-color="'#F5B0B3'"
      :pagination-active-color="'#FFFFFF'"
      :autoplay="isAutoPlay"
      :autoplay-timeout="5000"
      :space-padding="90"
      @page-change="pageChanges"
    >
      <slide v-for="(banner, idx) in home_banners" :key="banner._id">
        <div :class="{ 'first-load': initiate_load && idx === 0 }" class="item-slide">
          <img :src="getImage(banner.images)" :alt="banner.title" />
        </div>
      </slide>
    </Carousel>
    <template v-else>
      <template v-if="loading">
        <div id="loaderspinwrap" class="loaderspinwrap" style="padding: 30px 0"><div class="loaderspin"></div></div>
      </template>
    </template>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'HomeBanners',
  components: {
    Carousel,
    Slide,
  },
  props: {
    type: {
      default: 'primary',
      type: String,
    },
  },

  data() {
    return {
      initiate_load: true,
    };
  },

  computed: {
    home_primary_banners() {
      return this.$store.state.vmCola.cola_primary_home_banner;
    },
    home_secondary_banners() {
      return this.$store.state.vmCola.cola_secondary_home_banner;
    },
    beauty_atm_data() {
      return this.$store.state.beautyAtmData;
    },
    beauty_atm_banners() {
      if (this.beauty_atm_data?.banners?.length) {
        return this.beauty_atm_data.banners.map((b) => ({ images: [{ url: b.url }] }));
      }
      return [];
    },
    home_banners() {
      return this.type === 'primary'
        ? this.home_primary_banners
        : this.type === 'beauty'
        ? this.beauty_atm_banners
        : this.home_secondary_banners;
    },
    isAutoPlay() {
      return this.type === 'primary' ? this.home_primary_banners.length > 1 : this.home_secondary_banners.length > 1;
    },

    loading() {
      return this.$store.state.vmCola.loading_banner;
    },
  },

  methods: {
    getImage(images) {
      if (images.findIndex((res) => res.url) > -1) {
        return images.find((res) => res.url).url;
      }
    },
    pageChanges() {
      this.initiate_load = false;
    },
  },
};
</script>
